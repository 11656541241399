<template>
  <a-drawer
    title="服务商管理"
    width="800"
    :visible="visible"
    :closable="false"
    :footer="false"
    @close="onClose"
  >
    <div class="tool-view">
      <!-- 弹簧 -->
      <div style="flex: 1;"></div>
      <!-- 添加服务商 -->
      <a-button type="primary" @click="touchAdd"><plus-outlined /> 添加服务商</a-button>
    </div>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchEdit(record)">编辑</a>
          <a class="operation-item" @click="touchDataLink(record)">数据链接</a>
        </template>
      </template>
    </a-table>
    <!-- 添加 -->
    <AddSupplier ref="RefAddSupplier" @success="onAddSupplierSuccess"></AddSupplier>
    <!-- 加载 -->
    <Loading :loading="isSyncAllLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import { onBeforeMount, reactive, ref, createVNode } from 'vue'
import { nextTick } from 'process'
import AddSupplier from './AddSupplier.vue'
import { message } from 'ant-design-vue'
import { finderProviderList } from '@/api/copyright'
import Loading from '@/components/Loading'

// emit
const emit = defineEmits(['success'])
// 剧集简介
let RefAddSupplier = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
let isSyncAllLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 20,
  size: 'small',
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '服务商ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '服务商名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '关联达人数',
    dataIndex: 'daren_count',
    key: 'daren_count'
  },
  {
    title: '入库日期',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    fixed: 'right',
    width: 220
  }
])

// 显示
function showDrawer () {
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 获取列表
    getList()
  })
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    // 重置
    pagination.current = 1
    pagination.pageSize = 20
    visible.value = false
  }
}

// 添加服务商
function touchAdd (record) {
  RefAddSupplier.value.showDrawer(record)
}

// 编辑
function touchEdit (record) {
  RefAddSupplier.value.showDrawer(record)
}

// 数据链接
function touchDataLink (record) {
  Pub.COPY(`${record.name}：${Pub.DOMAIN_NAME()}/summary-data?type=2&id=${record.provider_number}`)
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  // 获取列表
  getList()
}

// 添加成功
function onAddSupplierSuccess () {
  // 获取列表
  getList()
  // 添加成功回调
  emit('success')
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  finderProviderList(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      // 重置列表数据
      dataSource.value = data.data
      pagination.total = data.total
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  display: flex;
}
.table-view {
  margin-top: 20px;
}

</style>