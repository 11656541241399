<template>
  <div class="page">
    <img class="bg-img" src="@/assets/img/bg.jpg">
    <div class="content">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="手机号登录" v-if="false">
          <a-form
            name="form-phone"
            ref="RefPhoneForm"
            :model="formStatePhone"
            @finish="submit"
          >
            <!-- 表单项 -->
            <a-form-item
              name="phone"
              validateTrigger="blur"
              :rules="[{ required: true, message: '请输入手机号' }, { pattern: '^1[3456789]\\d{9}$', message: '手机号格式错误'}]"
            >
              <a-input
                placeholder="请输入手机号"
                size="large"
                style="width: 300px;"
                :disabled="isLoading"
                v-model:value="formStatePhone.phone"
              >
                <template #prefix>
                  <UserOutlined class="site-form-item-icon" />
                </template>
              </a-input>
            </a-form-item>
            <!-- 表单项 -->
            <a-form-item
              name="code"
              validateTrigger="blur"
              :rules="[{ required: true, message: '请输入验证码' }]"
            >
              <a-input
                placeholder="请输入验证码"
                size="large"
                style="width: 300px;"
                :disabled="isLoading"
                v-model:value="formStatePhone.code"
              >
                <template #prefix>
                  <lock-outlined class="site-form-item-icon" />
                </template>
                <template #suffix>
                  <a-button @click="getCode" style="width: 100px">{{ codeString }}</a-button>
                </template>
              </a-input>
            </a-form-item>
            <!-- 提交 -->
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                size="large"
                class="submit-btn"
                :loading="isLoading"
              >
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" tab="账户密码登录" force-render>
          <a-form
            name="form-account"
            ref="RefAccountForm"
            :model="formStateAccount"
            @finish="submit"
          >
            <!-- 表单项 -->
            <a-form-item
              name="username"
              validateTrigger="blur"
              :rules="[{ required: true, message: '请输入账号' }]"
            >
              <a-input
                placeholder="请输入账号"
                size="large"
                style="width: 300px;"
                :disabled="isLoading"
                v-model:value="formStateAccount.username"
              >
                <template #prefix>
                  <UserOutlined class="site-form-item-icon" />
                </template>
              </a-input>
            </a-form-item>
            <!-- 表单项 -->
            <a-form-item
              name="password"
              validateTrigger="blur"
              :rules="[{ required: true, message: '请输入密码' }]"
            >
              <a-input-password
                placeholder="请输入密码"
                size="large"
                style="width: 300px;"
                :disabled="isLoading"
                v-model:value="formStateAccount.password"
              >
                <template #prefix>
                  <lock-outlined class="site-form-item-icon" />
                </template>
              </a-input-password>
            </a-form-item>
            <!-- 提交 -->
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                size="large"
                class="submit-btn"
                :loading="isLoading"
              >
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <!-- 加载 -->
      <Loading :loading="isLoading"></Loading>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { getSmsCode, login, userInfo } from '@/api/login'
import Pub from '@/utils/public'
import Loading from '@/components/Loading'
import md5 from 'js-md5'

// route
const route = useRoute()
// tabkey
let activeKey = ref('2')
// 手机号验证码表单实例
let RefPhoneForm = ref(null)
// 账户密码表单实例
let RefAccountForm = ref(null)
// 手机号验证码-表单数据
let formStatePhone = reactive({
  phone: '',
  code: ''
})
// 账户密码-表单数据
let formStateAccount = reactive({
  username: '',
  password: ''
})
// 手机号验证码-倒计时文案
let codeString = ref('获取验证码')
// 手机号验证码-定时器
let timer = ref(null)
// 加载
let isLoading = ref(false)

// 获取验证码
function getCode () {
  if (!timer.value) {
    const mobile = formStatePhone.phone
    if (!mobile) {
      message.error('手机号不能为空')
      return
    }
    if (!Pub.REG_IS_PHONE(mobile)) {
      message.error('手机号格式不正确')
      return
    }
    getSmsCode({
      phone: mobile
    }).then(res => {
      const { code, msg } = res
      if (code === 0) {
        message.success('验证码已发送')
        // 局部变量用于倒计时
        let timeNum = 60
        // 倒计时文案
        codeString.value = `${timeNum}s`
        timer.value = setInterval(() => {
          timeNum--
          codeString.value = `${timeNum}s`
          if (timeNum === 0) {
            // 清除定时器
            clearInterval(timer.value)
            setTimeout(() => {
              codeString.value = '获取验证码'
              timer.value = null
            }, 1000)
          }
        }, 1000)
      } else {
        message.error(res.message || msg)
      }
    }).catch(err => {
      message.error(err)
    })
  }
}

// 手机号验证码-登录
function submit () {
  // 表单验证
  (activeKey.value == 1 ? RefPhoneForm : RefAccountForm).value.validate()
  .then(() => {
    isLoading.value = true
    // 两种参数（1验证码  2账户密码）
    const params = (
      activeKey.value == 1 ?
      {
        type: 1,
        phone: formStatePhone.phone,
        code: formStatePhone.code
      } :
      {
        type: 2,
        username: formStateAccount.username,
        // 密码使用 MD5 加密
        password: md5(formStateAccount.password),
      }
    )
    login(params).then(res => {
      const { code, msg, data } = res
      if (code === 0) {
        // 设置token
        Pub.ACCESS_TOKEN(data.access_token)
        // 获取用户信息
        userInfo().then(r => {
          if (r.code === 0) {
            // 设置用户信息
            Pub.USERINFO(JSON.stringify(r.data))
            message.success('登录成功')
            // 成功后跳转
            Pub.JUMP_ROUTER(route.query.redirect || '/')
          }
        })
        
      } else {
        isLoading.value = false
        message.error(res.message || msg)
      }
    }).catch(err => {
      isLoading.value = false
      message.error(err)
    })
  })
}

</script>

<style lang="less" scoped>
.page {
  position: relative;
  background-color: #f5f5f5;
  height: 100%;
  overflow: hidden;
  .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    position: relative;
    width: 350px;
    height: 346px;
    background-color: #fff;
    border-radius: 10px;
    margin: 250px auto;
    padding-top: 10px;
    :deep(.ant-tabs-nav-list) {
      width: 100%;
      justify-content: space-around;
    }

    :deep(.ant-form) {
      margin-top: 20px;
      .ant-form-item {
        display: flex;
        justify-content: center;
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: center;
        }
        .ant-form-item-explain {
          padding-left: 24px;
        }
      }
    }
    .submit-btn {
      width: 300px;
      margin-top: 20px;
    }
  }
}
</style>