<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>达人运营</a-breadcrumb-item>
    <a-breadcrumb-item>视频号达人库</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <!-- 视频号 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">视频号：</a-col>
          <a-col :span="18">
            <a-input v-model:value="videoNumModel" class="tool-item" placeholder="请输入" />
          </a-col>
        </a-row>
      </a-col>
      <!-- 撮合服务商 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">撮合服务商：</a-col>
          <a-col :span="18">
            <a-select
              class="tool-item"
              v-model:value="supplierModel"
              placeholder="请选择"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in suppliers" :key="item.provider_id" :value="item.provider_id">{{ item.provider_name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="1" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button type="primary" @click="touchSearch">查询</a-button>
            <a-button style="margin-left: 16px; margin-right: 16px;" @click="touchReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap; text-align: right;">
            <a-button @click="touchAdd" type="primary"><plus-outlined /> 添加视频号</a-button>
            <a-button @click="touchSupplierManager" style="margin-left: 16px">服务商管理</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchEdit(record)">编辑</a>
        <a class="operation-item" @click="touchDataLink(record)">数据链接</a>
      </template>
      <template v-else>{{ record[column.key] || '-' }}</template>
    </template>
  </a-table>
  <!-- 自定义分页器 -->
  <a-pagination
    v-if="dataSource.length"
    style="margin: 16px 0; text-align: right;"
    v-model:current="pagination.current"
    v-model:pageSize="pagination.pageSize"
    :total="pagination.total"
    :showSizeChanger="pagination.showSizeChanger"
    @change="handlePaginationChange"
  />
  <!-- 新增 -->
  <Add ref="RefAdd" @success="onAddSuccess"></Add>
  <!-- 服务商管理 -->
  <SuppliersDrawer ref="RefSuppliersDrawer" @success="getSelectList"></SuppliersDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import Add from './components-video-number-expertdb/Add.vue'
import SuppliersDrawer from './components-video-number-expertdb/SuppliersDrawer.vue'
import { finderDarenList, finderDarenAll, finderProviderAll } from '@/api/copyright'

// 加载
let isLoading = ref(false)
// 新增结算
let RefAdd = ref(undefined)
// 服务商管理
let RefSuppliersDrawer = ref(undefined)
// 视频号
let videoNum = ref(undefined) 
let videoNumModel = ref(undefined) 
// 服务商
let supplier = ref(undefined)
let supplierModel = ref(undefined)
let suppliers = ref([])
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '达人ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '视频号ID',
    dataIndex: 'wx_finder_id',
    key: 'wx_finder_id'
  },
  {
    title: '视频号名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '撮合服务商',
    dataIndex: 'provider_name',
    key: 'provider_name'
  },
  {
    title: '入库日期',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取推广数据列表
  getList()
})

// 模糊匹配
function filterOption (input, option) {
  console.log(option)
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 添加视频号
function touchAdd () {
  RefAdd.value.showDrawer({})
}

// 服务商管理
function touchSupplierManager () {
  RefSuppliersDrawer.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record)
}

// 数据链接
function touchDataLink (record) {
  Pub.COPY(`${record.name}：${Pub.DOMAIN_NAME()}/summary-data?type=1&id=${record.wx_finder_id}`)
  
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 分页处理
function handlePaginationChange (current, pageSize) {
  pagination.current = current
  pagination.pageSize = pageSize
  getList()
}

// 查询
function touchSearch () {
  // 调整参数
  videoNum.value = videoNumModel.value
  supplier.value = supplierModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  videoNum.value = undefined
  videoNumModel.value = undefined
  supplier.value = undefined
  supplierModel.value = undefined
  // 初始化获取
  touchSearch()
}

function onAddSuccess () {
  // 更新检索列表
  getSelectList()
  // 搜搜
  touchSearch()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    wx_finder_id: videoNum.value,
    provider_id: supplier.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  finderDarenList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 初始化选择项列表
function getSelectList () {
  finderProviderAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      suppliers.value = data
    }
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>