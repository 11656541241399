import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ===================================《 SummaryData 》

// H5看板——达人汇总统计
export function darenPay(parameter) {
  return axios({
    url: BASE_URL + '/h5/daren_pay',
    method: 'get',
    params: parameter
  })
}

// H5看板——达人明细数据
export function darenList(parameter) {
  return axios({
    url: BASE_URL + '/h5/daren_list',
    method: 'get',
    params: parameter
  })
}

// H5看板——服务商汇总统计
export function providerPay(parameter) {
  return axios({
    url: BASE_URL + '/h5/provider_pay',
    method: 'get',
    params: parameter
  })
}

// H5看板——服务商明细
export function providerList(parameter) {
  return axios({
    url: BASE_URL + '/h5/provider_list',
    method: 'get',
    params: parameter
  })
}