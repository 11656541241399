<template>
  <div class="summary-data">
    <!-- 汇总数据 -->
    <div class="summary-header">
      <!-- 提示 -->
      <a-alert type="warning" show-icon>
        <template #message>今日数据更新于 {{ info.refresh_time }}</template>
        <template #icon><SoundOutlined /></template>
      </a-alert>
      <!-- 汇总 -->
      <div class="summary-header-info">
        <div class="summary-header-info-item">
          <div class="title">今日充值</div>
          <div class="desc">
            <span class="num">{{ info.today_amount }}</span>
            <!-- <span class="unit">元</span> -->
          </div>
        </div>
        <div class="summary-header-info-item">
          <div class="title">昨日充值</div>
          <div class="desc">
            <span class="num">{{ info.yesterday_amount }}</span>
            <!-- <span class="unit">元</span> -->
          </div>
        </div>
        <div class="summary-header-info-item">
          <div class="title">累计充值</div>
          <div class="desc">
            <span class="num">{{ info.total_amount }}</span>
            <!-- <span class="unit">元</span> -->
          </div>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="space-line"></div>
      <!-- 筛选 -->
      <div class="summary-list-tool">
        <div class="title" v-if="query.type == 1">视频充值明细</div>
        <div class="title" v-else-if="query.type == 2">达人充值明细</div>
        <div class="title" v-else>　</div>
        <div class="tool">
          <a-dropdown>
            <span class="ant-dropdown-link" @click.prevent>
              {{ yearMonth }} <DownOutlined />
            </span>
            <template #overlay>
              <a-menu style="max-height: 200px; overflow: auto;" @click="onMenuClick">
                <a-menu-item v-for="(item, index) in yearMonthArray" :key="index">
                  {{ item }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <div style="flex: 1;"></div>
          <div>共计{{ sumarry.total_income_amount }}</div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="summary-list" ref="RefSummaryList" @scroll="handleScroll">
      <!-- 列表 Item -->
      <div
        class="summary-list-item"
        v-for="(item,index) in dataSource"
        :key="index"
      >
        <div class="item-left">
          <div class="name">
            <div class="title">{{ item.title }}</div>
            <CopyOutlined style="margin-left: 0.4rem; font-size: 1.6rem;" @click="onItemClick(item)" v-if="query.type == 1" />
          </div>
          <div class="date">{{ item.date }}</div>
        </div>
        <div style="flex: 1;"></div>
        <div class="item-right">{{ item.total_income_amount }}</div>
        <div class="item-space-line"></div>
      </div>
      <!-- 没有更多了 -->
      <div class="summary-list-footer" v-if="isLoading" >加载中...</div>
      <div class="summary-list-footer" v-if="!isLoading && isNoMore">没有数据啦~</div>
    </div>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { setDocumentTitle } from '@/utils/domUtil'
import { darenPay, darenList, providerPay, providerList } from '@/api/h5'
import Pub from '@/utils/public'
import dayjs from 'dayjs'

let RefSummaryList = ref(null)
// 加载
let isLoading = ref(true)
// 没有更多数据了
let isNoMore = ref(false)
// 年月
let yearMonthArray = ref(getYearMonthArray(2025, 1))
let yearMonth = ref(yearMonthArray.value[yearMonthArray.value.length - 1])
let yearMonthValue = ref(yearMonth.value.replace('年', '-').replace('月', ''))
// 分页器
let pagination = ref({
  total: 0,
  current: 1,
  pageSize: 20
})
// 页面参数
let query = ref({})
// 头部汇总
let info = ref({})
// 汇总
let sumarry = ref({})
// 列表数据
let dataSource = ref([])

onBeforeMount (() => {
  // query带入uid字段
  const route = useRoute()
  // type: 1 视频号数据 2 服务商数据
  query.value = route.query || {}
})

onMounted (() => {
  // 获取汇总数据
  getInfo()
  // 获取列表数据
  getList()
})

// 拷贝
function onItemClick (item) {
  if (query.value.type == 1) {
    Pub.COPY(item.title)
  }
}

function onMenuClick (e) {
  // 清空列表
  dataSource.value = []
  // 设置状态
  isLoading.value = true
  isNoMore.value = false
  // 切换
  yearMonth.value = yearMonthArray.value[e.key]
  yearMonthValue.value = yearMonth.value.replace('年', '-').replace('月', '')
  // 获取数据
  getList()
}

// 获取汇总数据
function getInfo () {
  if (query.value.type == 1) {
    darenPay({ wx_finder_id: query.value.id }).then(res => {
      const { code, data } = res
      if (code === 0) {
        info.value = data || {}
        setDocumentTitle(info.value.title)
      }
    })
  } else if (query.value.type == 2) {
    providerPay({ provider_number: query.value.id }).then(res => {
      const { code, data } = res
      if (code === 0) {
        info.value = data || {}
        setDocumentTitle(info.value.title)
      }
    })
  }
}

// 获取列表数据
function getList (isMore) {
  if (!isMore) {
    pagination.value.current = 1
  }
  isLoading.value = true
  if (query.value.type == 1) {
    darenList({ wx_finder_id: query.value.id, date: yearMonthValue.value, page: pagination.value.current, page_size: pagination.value.pageSize }).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0) {
        pagination.value.current++
        dataSource.value = dataSource.value.concat(Array.from(data.data || []))
        pagination.value.total = data.total
        sumarry.value = data.sumarry
        if (dataSource.value.length === pagination.value.total) {
          isNoMore.value = true
        }
      }
    }).catch(() => {
      isLoading.value = false
    })
  } else if (query.value.type == 2) {
    providerList({ provider_number: query.value.id, date: yearMonthValue.value, page: pagination.value.current, page_size: pagination.value.pageSize }).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0) {
        pagination.value.current++
        dataSource.value = dataSource.value.concat(Array.from(data.data || []))
        pagination.value.total = data.total
        sumarry.value = data.sumarry
        if (dataSource.value.length === pagination.value.total) {
          isNoMore.value = true
        }
      }
    }).catch(() => {
      isLoading.value = false
    })
  }
}

// 滚动到底部时触发加载更多
function handleScroll() {
  const bottom = RefSummaryList.value.scrollHeight > (RefSummaryList.value.scrollTop + RefSummaryList.value.clientHeight + 20)
  if (bottom && dataSource.value.length < pagination.value.total && !isLoading.value) {
    getList(true)
  }
}

/**
 * 获取指定年月到当前时间的所有年月数组值
 * @param {number} startYear - 开始的年份，例如 2022
 * @param {number} startMonth - 开始的月份，例如 1（代表 1 月）
 * @returns {string[]} - 所有年月数组值，格式为 ["YYYY年MM月", ...]
 */
 function getYearMonthArray(startYear, startMonth) {
  // 解析开始时间
  const startDate = dayjs(`${startYear}-${startMonth}`, 'YYYY-M')
  // 当前时间
  const currentDate = dayjs()
  // 存放数组
  const yearMonthArray = []
  let tempDate = startDate.clone()
  // 循环从开始年月到当前年月
  while (tempDate.isBefore(currentDate, 'month') || tempDate.isSame(currentDate, 'month')) {
    // 添加格式化年月文案
    yearMonthArray.push(tempDate.format('YYYY年MM月'))
    // 增加一个月
    tempDate = tempDate.add(1, 'month')
  }
  // 返回
  return yearMonthArray;
}
</script>

<style>
html {
  font-size: 10px;
}
html, body, #app {
  min-width: auto;
  overflow: hidden;
}
</style>

<style lang="less" scoped>
.summary-data {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  .summary-header {
    .summary-header-info {
      padding: 1.6rem 2rem 1.6rem 2rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 10rem;
      .summary-header-info-item {
        flex-shrink: 0;
        flex: 1;
        .title {
          font-size: 1.8rem;
          color: rgba(0, 0, 0, 0.6);
        }
        .desc {
          .num {
            font-size: 2.2rem;
            font-weight: 500;
            color: rgba(0, 0, 0, 1);
          }
          .unit {
            font-size: 2.0rem;
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .space-line {
      width: 100%;
      height: 1.8rem;
      background-color: #E9E9E9;
    }
    .summary-list-tool {
      padding: 2rem 2rem 0 2rem;
      .title {
        width: 100%;
        font-weight: 500;
        font-size: 2.4rem;
      }
      .tool {
        margin-top: 1rem;
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.7);
        display: flex;
      }
    }
  }
  .summary-list {
    padding: 0 2rem;
    font-size: 1.8rem;
    height: calc(100% - 25.4rem);
    overflow: auto;
    .summary-list-item {
      padding: 1.6rem 0;
      position: relative;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.7);
      .item-left {
        .name {
          display: flex;
          align-items: center;
          .title {
            max-width: 20rem;
            direction: rtl;               /* 设置从右到左的文本方向 */
            white-space: nowrap;          /* 防止换行 */
            overflow: hidden;             /* 隐藏溢出的文本 */
            text-overflow: ellipsis;      /* 在超出范围时使用省略号 */
          }
        }
        .date {
          margin-top: 0.6rem;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .item-right {
      }
      .item-space-line {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-top: 2rem;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .summary-list-footer {
      width: 100%;
      text-align: center;
      padding: 1.6rem 0;
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>