<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>项目配置</a-breadcrumb-item>
    <a-breadcrumb-item>抖小播放器配置</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 返回推荐剧 -->
      <template v-if="column.key === 'is_return_status'">
        <a-switch
          v-model:checked="record.config_douyin.is_return_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record, 1)"
        />
      </template>
      <!-- 完播推荐剧 -->
      <template v-if="column.key === 'is_end_status'">
        <a-switch
          v-model:checked="record.config_douyin.is_end_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record, 2)"
        />
      </template>
      <!-- 续播定位 -->
      <template v-if="column.key === 'is_continue_status'">
        <a-switch
          v-model:checked="record.config_douyin.is_continue_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record, 3)"
        />
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑项目设置 -->
  <Add ref="RefAdd" @success="getList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Add from './components-gift-management/Add'
import { message } from 'ant-design-vue'
import { ttConfigList, ttRecPosition } from '@/api/copyright'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 搜索
let search = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 200,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '抖小名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '返回推荐剧',
    dataIndex: 'is_return_status',
    key: 'is_return_status'
  },
  {
    title: '完播推荐剧',
    dataIndex: 'is_end_status',
    key: 'is_end_status'
  },
  {
    title: '续播定位',
    dataIndex: 'is_continue_status',
    key: 'is_continue_status'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取项目列表
  getList()
})

// 获取项目列表
function getList () {
  isLoading.value = true
  const params = {
    kw: search.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  ttConfigList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.config_douyin.is_return_status = Boolean(item.config_douyin.is_return_status)
        item.config_douyin.is_end_status = Boolean(item.config_douyin.is_end_status)
        item.config_douyin.is_continue_status = Boolean(item.config_douyin.is_continue_status)
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 切换状态
function statusChange (e, record, type) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    type: type,
    status: Number(e)
  }
  ttRecPosition(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getList()
  }).catch(() => {
    // isLoading.value = false
    getList()
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>