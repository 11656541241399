<template>
  <a-drawer
    title="创建/编辑短剧"
    width="800"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 竖版封面 -->
      <a-form-item
        label="竖版封面"
        name="cover"
        :rules="[{ required: true, validator: validatorCover }]"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="1"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'cover') }"
          :fileList="formState.cover"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif</div>
        <div class="form-upload-list" v-if="formState.cover.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.cover"
            :key="index"
          >
            <ImageView
              class="upload-image"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('cover', index)"
            >
            </ImageView>
            <!-- 同步抖小 -->
            <TtSyncCoverItem class="sync-tt" :item="item"></TtSyncCoverItem>
            <!-- 同步快小 -->
            <KsSyncCoverItem class="sync-tt" :item="item"></KsSyncCoverItem>
          </div>
        </div>
      </a-form-item>
      <!-- 横版封面 -->
      <a-form-item
        label="横版封面"
        name="horizontalCover"
        :rules="[{ required: false }]"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="5"
          :imgSizeMode="3"
          :imgSizeWidth="208"
          :imgSizeHeight="124"
          imgSizeError="图片尺寸为 208 * 124"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'horizontalCover') }"
          :fileList="formState.horizontalCover"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸为 208×124</div>
        <div class="form-upload-list" v-if="formState.horizontalCover.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.horizontalCover"
            :key="index"
          >
            <ImageView
              class="upload-image upload-image-horizontal"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('horizontalCover', index)"
            >
            </ImageView>
            <!-- 同步抖小 -->
            <!-- <TtSyncCoverItem class="sync-tt" :item="item"></TtSyncCoverItem> -->
          </div>
        </div>
      </a-form-item>
      <!-- 视频集锦 -->
      <!-- <a-form-item
        label="视频集锦"
        name="video_highlight"
        :rules="[{ required: true, validator: validatorVideos }]"
      >
        <Upload
          accept=".mp4"
          uploadSource="video_highlight"
          :fileNumber="5"
          :beforeUploadPro="beforeUploadVideoPro"
          :fileList="formState.video_highlight"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.mp4，2分钟之内的视频，主要用作付费引导</div>
        <div class="form-upload-list" v-if="formState.video_highlight.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.video_highlight"
            :key="index"
            :isShowPlay="true"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteVideo(index)"
            @play="touchPlayVideo(index, item)"
          >
          </ImageView>
        </div>
      </a-form-item> -->
      <!-- 剧名 -->
      <a-form-item
        label="剧名"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.name"
          placeholder="请输入"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 备注剧名 -->
      <a-form-item
        label="备注剧名"
        name="remark_name"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.remark_name"
          placeholder="请输入"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 剧情分类 -->
      <a-form-item
        label="剧情分类"
        name="male_and_female"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.male_and_female"
          placeholder="请选择"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="onMaleAndFemaleChange"
        >
          <a-select-option
            v-for="item in gdtTypeList"
            :key="`${item.value}-${item.label}`"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 剧情类型 -->
      <a-form-item
        label="剧情类型"
        name="type_ids"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.type_ids"
          mode="multiple"
          placeholder="请选择"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in plotTypes"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 剧情标签 -->
      <a-form-item
        label="剧情标签"
        name="tag_ids"
      >
        <a-select
          v-model:value="formState.tag_ids"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in plotTags"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 广点通类目 -->
      <a-form-item
        label="广点通类目"
        name="gdt_category_ids"
        :rules="[{ required: true }]"
      >
        <a-cascader
          showSearch
          v-model:value="formState.gdt_category_ids"
          :options="gdtTypeSubList"
          placeholder="请选择"
        />
        <!-- <a-select
          v-model:value="formState.tag_ids"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in plotTags"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select> -->
      </a-form-item>
      <!-- 短剧推荐语 -->
      <a-form-item
        label="短剧推荐语"
        name="recommendation"
      >
        <a-input
          v-model:value="formState.recommendation"
          placeholder="请输入（12字以内）"
          :maxlength="12"
        />
      </a-form-item>
      <!-- 剧情简介 -->
      <a-form-item
        label="剧情简介"
        name="description"
      >
        <a-textarea
          allow-clear
          v-model:value="formState.description"
          placeholder="请输入"
          :maxlength="2000"
          :rows="4"
        />
      </a-form-item>
      <!-- 剧集简介（订阅）-->
      <a-form-item
        label="剧集简介（订阅）"
        name="push_description"
        :rules="[{ message: '请输入' }]"
      >
        <EmojiTextarea
          placeholder="请输入"
          v-model="formState.push_description"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 温馨提醒（订阅）-->
      <a-form-item
        label="温馨提醒（订阅）"
        name="push_warm_reminder"
        :rules="[{ message: '请选择' }]"
      >
        <a-select v-model:value="formState.push_warm_reminder" placeholder="请选择">
          <a-select-option value="点击小程序，观看更新内容~">点击小程序，观看更新内容~</a-select-option>
          <a-select-option value="您追的剧集更新啦，快来追剧吧！">您追的剧集更新啦，快来追剧吧！</a-select-option>
          <a-select-option value="爆款新剧~不看后悔！点击立看~">爆款新剧~不看后悔！点击立看~</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 爆点链接 -->
      <a-form-item
        label="爆点链接"
        name="detonation_point_url"
      >
        <a-input
          v-model:value="formState.detonation_point_url"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 批量下载链接 -->
      <a-form-item
        label="批量下载链接"
        name="batch_download_url"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.batch_download_url"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 备案剧  -->
      <a-form-item
        label="备案剧"
        name="is_record"
        :rules="[{ required: true }]"
      >
        <a-radio-group class="form-input" v-model:value="formState.is_record">
          <a-radio
            v-for="item in FilingTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 备案号 -->
      <a-form-item
        label="备案号"
        name="ordinary_record_num"
      >
        <a-input
          v-model:value="formState.ordinary_record_num"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 微信剧目ID -->
      <a-form-item
        label="微信剧目ID"
        name="wechat_drama_id"
        :rules="[{ validator: validatorWechatDramaId }]"
      >
        <a-input
          v-model:value="formState.wechat_drama_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 抖音剧目ID -->
      <!-- <a-form-item
        label="抖音剧目ID"
        name="douyin_drama_id"
        :rules="[{ validator: validatorWechatDramaId }]"
      >
        <a-input
          v-model:value="formState.douyin_drama_id"
          placeholder="请输入"
        />
      </a-form-item> -->
      <!-- 小红书剧目ID -->
      <a-form-item
        label="小红书剧目ID"
        name="xhs_drama_id"
      >
        <a-input
          v-model:value="formState.xhs_drama_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 总集数 -->
      <a-form-item
        label="总集数"
        name="total_ep"
        :rules="[{ required: true, validator: validatortotal_ep }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.total_ep"
          placeholder="请输入"
          suffix="集"
        />
      </a-form-item>
      <!-- 视频比例 -->
      <a-form-item
        label="视频比例"
        name="drama_ratio"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.drama_ratio"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in VideoScales"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 导演 -->
      <a-form-item
        label="导演"
        name="directors"
      >
        <a-input
          class="form-input"
          v-model:value="formState.directors"
          placeholder="请输入，多个用顿号间隔"
        />
      </a-form-item>
      <!-- 主演 -->
      <!-- <a-form-item
        label="主演"
        name="actors"
      >
        <a-input
          class="form-input"
          v-model:value="formState.actors"
          placeholder="请输入，多个用顿号间隔"
        />
      </a-form-item> -->
      <!-- 演员 -->
      <a-form-item
        label="演员"
        :name="['actor_list']"
        :rules="[{ required: true, message: '请添加演员' }]"
      > 
        <template v-for="(item, index) in formState.actor_list">
          <a-form-item
            :name="['actor_list', index]"
            :rules="[{ validator: validatorActor }]"
          >
            <div style="line-height: 34px; display: flex;">
              <span style="color: #8D8D8D;">演员{{ index + 1 }}：</span>
              <div style="flex: 1;"></div>
              <a @click="touchDeleteActor(index)">删除</a>
            </div>
            <Upload
              accept=".png,.jpg,.gif"
              uploadSource="TtOperation2"
              :beforeUploadPro="beforeUploadPro"
              :uploadResult="(fileJson, res, record) => uploadResult(item, fileJson, res, record)"
              :fileList="item.photo_material"
              :uploadBucket="$pub.BUCKET_PUB()"
              :imgSizeMode="22"
              :imgSizeWidth="240"
              :imgSizeHeight="240"
              imgSizeError="图片最小尺寸为 240 * 240"
              :imgScaleMode="1"
              :imgScaleWidth="240"
              :imgScaleHeight="240"
              imgScaleError="图片尺寸比例不正确"
            >
              <template #up-title>上传照片</template>
            </Upload>
            <div class="form-upload-hint">支持.png .jpg .gif，建议尺寸为 240 x 240，或尺寸更大的等比图片</div>
            <div class="form-upload-list" v-if="item.photo_material && item.photo_material.length">
              <ImageView
                class="form-upload-item"
                v-for="(item, index) in item.photo_material"
                :key="index"
                :url="$pub.CDN_URL(item.url)"
                :fileJson="item"
                :isShowDelete="false"
              >
              </ImageView>
            </div>
            <div style="display: flex; margin-top: 10px;">
              <a-input
                style="flex: 1; margin-right: 4px;"
                v-model:value="item.name"
                placeholder="请输入姓名"
              />
              <a-input
                style="flex: 1; margin-left: 4px;"
                v-model:value="item.role"
                placeholder="请输入角色"
              />
            </div>
            <a-textarea
              style="margin-top: 10px;"
              v-model:value="item.profile"
              placeholder="请输入演员简介"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              :maxlength="1000"
            />
          </a-form-item>
        </template>
        <a @click="touchAddActor">+ 添加演员</a>
      </a-form-item>
      <!-- 评级 -->
      <a-form-item
        label="评级"
        name="grade"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.grade"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in Grades"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 片源  -->
      <a-form-item
        label="片源"
        name="film_type"
        :rules="[{ required: true }]"
      >
        <a-radio-group class="form-input" v-model:value="formState.film_type" @change="changeFilmType">
          <a-radio
            v-for="item in FilmTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 片源为自制的联动字段 -->
      <template v-if="formState.film_type === 1">
        <!-- 剧本供应商 -->
        <a-form-item
          label="剧本供应商"
          name="drama_supplier_id"
          :rules="[{ required: true }]"
        >
          <a-select
            class="form-input"
            show-search
            v-model:value="formState.drama_supplier_id"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="item in supplierListDrama"
              :key="`${item.id}-${item.name}`"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 剧本价格 -->
        <a-form-item
          label="剧本价格"
          name="drama_money"
          :rules="[{ required: true, validator: validatorMoney }]"
        >
          <a-input
            class="form-input"
            v-model:value="formState.drama_money"
            placeholder="请输入"
            suffix="元"
          />
        </a-form-item>
        <!-- 摄制供应商 -->
        <a-form-item
          label="摄制供应商"
          name="supplier_id"
          :rules="[{ required: true }]"
        >
          <a-select
            class="form-input"
            show-search
            v-model:value="formState.supplier_id"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="item in supplierListShot"
              :key="`${item.id}-${item.name}`"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 摄制结算方式 -->
        <a-form-item
          label="摄制结算方式"
          name="cash_type"
          :rules="[{ required: true }]"
        >
          <a-select
            class="form-input"
            v-model:value="formState.cash_type"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option
              v-for="item in CashTypes"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 摄制底价 -->
        <a-form-item
          label="摄制底价"
          name="money"
          :rules="[{ required: true, validator: validatorMoney }]"
        >
          <a-input
            class="form-input"
            v-model:value="formState.money"
            placeholder="请输入"
            suffix="元"
          />
        </a-form-item>
        <!-- 分成比例 -->
        <a-form-item
          label="分成比例"
          name="cash_rate"
          :rules="[{ required: true, validator: validatorCashRate }]"
          v-if="formState.cash_type === 2"
        >
          <a-input
            class="form-input"
            v-model:value="formState.cash_rate"
            placeholder="请输入"
            suffix="%"
          />
        </a-form-item>
      </template>
      <!-- 片源为外采的联动字段 -->
      <template v-if="formState.film_type === 2">
        <!-- 成片供应商 -->
        <a-form-item
          label="成片供应商"
          name="film_supplier_id"
          :rules="[{ required: true }]"
        >
          <a-select
            class="form-input"
            show-search
            v-model:value="formState.film_supplier_id"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="item in supplierListFilm"
              :key="`${item.id}-${item.name}`"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 成片结算方式 -->
        <a-form-item
          label="成片结算方式"
          name="cash_type"
          :rules="[{ required: true }]"
        >
          <a-select
            class="form-input"
            v-model:value="formState.cash_type"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option
              v-for="item in CashTypes"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 底价 -->
        <a-form-item
          label="底价"
          name="money"
          :rules="[{ required: true, validator: validatorMoney }]"
        >
          <a-input
            class="form-input"
            v-model:value="formState.money"
            placeholder="请输入"
            suffix="元"
          />
        </a-form-item>
        <!-- 分成比例 -->
        <a-form-item
          label="分成比例"
          name="cash_rate"
          :rules="[{ required: true, validator: validatorCashRate }]"
          v-if="formState.cash_type === 2"
        >
          <a-input
            class="form-input"
            v-model:value="formState.cash_rate"
            placeholder="请输入"
            suffix="%"
          />
        </a-form-item>
        <!-- 版权期限 -->
        <a-form-item
          class="form-item-clear"
          label="版权期限"
          :rules="[{ required: true }]"
        >
          <a-row>
            <a-col :span="12">
              <!-- 有效期类型 -->
              <a-form-item
                name="copyrightTermType"
                :rules="[{ required: true, validator: validatorCopyrightTermType }]"
              >
                <a-select
                  v-model:value="formState.copyrightTermType"
                  placeholder="请选择"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in CopyrightTermTypes"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="formState.copyrightTermType === 2" style="padding-left: 10px;">
              <!-- 有效期时间 -->
              <a-form-item
                name="copyright_date"
                :rules="[{ required: true, validator: validatorCopyrightTerm }]"
              >
                <a-date-picker
                  style="width: 100%;"
                  v-model:value="formState.copyright_date"
                  :format="dateFormat"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-item>
        <!-- 独家授权 -->
        <a-form-item
          label="独家授权"
          name="is_unique_auth"
        >
          <a-radio-group class="form-input" v-model:value="formState.is_unique_auth">
            <a-radio
              v-for="item in ExclusiveTypes"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </template>
      <!-- 全集解锁价格 -->
      <a-form-item
        label="全集解锁价格"
        name="drama_price"
        :rules="[{ required: true, validator: validatorMoney }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.drama_price"
          placeholder="请输入"
          suffix="元"
        />
      </a-form-item>
      <!-- 解锁全集特价 -->
      <a-form-item
        label="解锁全集特价"
        name="special_price"
        :rules="[{ required: true, validator: validatorMoney }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.special_price"
          placeholder="请输入"
          suffix="元"
        />
      </a-form-item>
      <!-- 默认付费模板 -->
      <a-form-item
        label="默认付费模板"
        name="charge_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          show-search
          style="width: 80%;"
          v-model:value="formState.charge_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filter-option="filterOption"
          @mouseleave="mouseleave"
        >
          <template
            v-for="item in defaultList"
          >
            <a-select-option
              v-if="item.detail && item.detail.length"
              :key="`${item.id}-${item.name}`"
              :value="item.id"
              @mouseenter="mouseenter(item.id)"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
        <a class="recharge-delete" @click="touchPreview(formState.charge_id)">预览</a>
      </a-form-item>
      <!-- 允许广告解锁  -->
      <a-form-item
        label="允许广告解锁"
        name="drama_ad_unlock"
        :rules="[{ required: true }]"
      >
        <a-radio-group class="form-input" v-model:value="formState.drama_ad_unlock">
          <a-radio
            v-for="item in FilingTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 预览 -->
    <PreviewModal ref="RefPreviewModal"></PreviewModal>
    <!-- 支付模版预览 -->
    <PayPreviewModal ref="RefPayPreviewModal"></PayPreviewModal>
    <!-- 预览模版 -->
    <PayPreviewView ref="RefPayPreviewView" :right="840"></PayPreviewView>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import { VideoScales, Grades, CashTypes, CopyrightTermTypes, ExclusiveTypes, ClassifySettings, FilmTypes, FilingTypes } from '@/utils/constantList'
import ImageView from '@/components/ImageView'
import Loading from '@/components/Loading'
import Upload from '@/components/Upload'
import Pub from '@/utils/public'
import PayPreviewModal from '@/views/operate/config/components-coin-custom/PreviewModal'
import PayPreviewView from '@/views/operate/config/components-coin-custom/PreviewView'
import { supplierAll, dramaAdd, dramaEdit, dramaDetail, dramaGdtTypeList, chargePanelAll } from '@/api/copyright'
import { getAllClassifyList } from '@/api/system'
import dayjs from 'dayjs'
import PreviewModal from '@/components/preview/PreviewModal'
import TtSyncCoverItem from './TtSyncCoverItem.vue'
import KsSyncCoverItem from './KsSyncCoverItem.vue'
import EmojiTextarea from '@/components/EmojiTextarea'
import { message } from 'ant-design-vue'

// 视频预览
let RefPreviewModal = ref(null)
// 支付模版预览
const RefPayPreviewModal = ref(null)
const RefPayPreviewView = ref(null)
// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 修改对象
let filmJson = ref(null)
// 摄制供应商列表
let supplierListShot = ref([])
// 剧本供应商列表
let supplierListDrama = ref([])
// 成片供应商列表
let supplierListFilm = ref([])
// 剧情标签列表
let plotTags = ref([])
// 剧情类型列表
let plotTypes = ref([])
// 广点通类型列表
let gdtTypeList = ref([])
let gdtTypeSubList = ref([])
// 默认充值模版列表
let defaultList = ref([])
// 日期格式
const dateFormat = 'YYYY-MM-DD'
// 表单参数
const formState = ref({
  // 封面
  cover: [],
  // 横版封面
  horizontalCover: [],
  // 视频集锦
  video_highlight: [],
  // 剧名
  name: undefined,
  // 备注剧名
  remark_name: undefined,
  // 剧情分类
  male_and_female: undefined,
  // 剧情类型
  type_ids: [],
  // 剧情标签
  tag_ids: [],
  // 广点通类目
  gdt_category: [],
  gdt_category_ids: [],
  // 短剧推荐语
  recommendation: undefined,
  // 剧情简介
  description: undefined,
  // 剧集简介（订阅）
  push_description: undefined,
  // 温馨提示（订阅）
  push_warm_reminder: undefined,
  // 爆点链接
  detonation_point_url: undefined,
  // 批量下载链接
  batch_download_url: undefined,
  // 备案剧
  is_record: 0,
  // 备案号
  ordinary_record_num: undefined,
  // 微信剧目ID
  wechat_drama_id: undefined,
  // 抖音剧目ID
  douyin_drama_id: undefined,
  // 总集数
  total_ep: undefined,
  // 视频比例
  drama_ratio: undefined,
  // 导演
  directors: undefined,
  // 演员
  actors: undefined,
  actor_list:[],
  // 评级
  grade: undefined,
  // 片源
  film_type: 1,
  // 摄制供应商
  supplier_id: undefined,
  // 剧本供应商
  drama_supplier_id: undefined,
  // 成片供应商
  film_supplier_id: undefined,
  // 结算方式
  cash_type: undefined,
  // 价格
  money: undefined,
  // 全集解锁价格
  drama_price: undefined,
  // 解锁全集特价
  special_price: undefined,
  // 默认模板
  charge_id: undefined,
  // 剧本价格
  drama_money: undefined,
  // 分成比例
  cash_rate: undefined,
  // 版权期限
  copyright_date: undefined,
  // 版权期限类型
  copyrightTermType: undefined,
  // 独家授权
  is_unique_auth: 0,
  // 允许广告解锁
  drama_ad_unlock: 0
})

// 显示
function showDrawer (data) {
  // 记录
  filmJson.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 重置
    resetFields()
    changeFilmType()
    // 获取选择项
    getSelectList()
    // 获取广点通类型
    getDramaGdtTypeList()
    // 先获取标签、类型列表、供应商列表，再获取详情
    Promise.all([getPlotTypes(), getPlotTags(), getSupplierAll(1), getSupplierAll(2), getSupplierAll(3)]).then(() => {
      // 获取详情
      if (!!data) { getDramaDetail() }
    })
  })
}

// 进入元素
function mouseenter (id) {
  RefPayPreviewView.value.show(id)
}

// 离开元素
function mouseleave () {
  RefPayPreviewView.value.hide()
}

// 预览充值模版
function touchPreview(charge_id) {
  RefPayPreviewModal.value.showModal(charge_id)
}

// 添加演员
function touchAddActor () {
  const json = {
    name: undefined,
    photo_material: [],
    role: undefined,
    profile: undefined
  }
  formState.value.actor_list.push(json)
}

function touchDeleteActor (index) {
  formState.value.actor_list.splice(index, 1)
}

// 获取选择项
function getSelectList () {
  // 默认模版
  chargePanelAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      defaultList.value = data
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
  formState.value.actor_list = []
}

// 切换片源时，相关字段清空
function changeFilmType () {
  const type = formState.value.film_type
  if (type === 1) {
    formState.value.drama_supplier_id = undefined
    formState.value.drama_money = undefined
    formState.value.drama_price = undefined
    formState.value.supplier_id = undefined
  } else {
    formState.value.film_supplier_id = undefined
    formState.value.copyright_date = undefined
    formState.value.copyrightTermType = undefined
    formState.value.is_unique_auth = 0
  }
  formState.value.cash_type = undefined
  formState.value.money = undefined
  formState.value.cash_rate = undefined
}

// 片库详情
function getDramaDetail () {
  isLoading.value = true
  dramaDetail({ id: filmJson.value.id }).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      formState.value = data
      formState.value.horizontalCover = data.horizontal_cover || []
      formState.value.video_highlight = data.video_highlight || []
      formState.value.charge_id = !!data.charge_id ? data.charge_id : undefined
      // 广点通分类
      formState.value.male_and_female = Number(data.male_and_female)
      onMaleAndFemaleChange(formState.value.male_and_female)
      if (formState.value.gdt_category && formState.value.gdt_category.length) {
        formState.value.gdt_category_ids = [formState.value.gdt_category[0].value, formState.value.gdt_category[1].value]
      }
      // 将【选中的并且已停用】的标签列表，拼接在正常的标签列表之后
      const deactiveTags = data.tags.filter(item => item.status === 0)
      if (deactiveTags.length) {
        plotTags.value = plotTags.value.concat(deactiveTags)
      }
      // 将【选中的并且已停用】的类型列表，拼接在正常的类型列表之后
      const deactiveTypes = data.drama_types.filter(item => item.status === 0)
      if (deactiveTypes.length) {
        plotTypes.value = plotTypes.value.concat(deactiveTypes)
      }
      // 保证已停止合作的摄制供应商正常展示
      if (data.supplier_id) {
        supplierListShot.value = Pub.mergeArr(supplierListShot.value, [
          {
            id: data.supplier_id,
            name: data.supplier.name
          }
        ], 'id')
      } else {
        formState.value.supplier_id = undefined
      }
      // 保证已停止合作的剧本供应商正常展示
      if (data.drama_supplier_id) {
        supplierListDrama.value = Pub.mergeArr(supplierListDrama.value, [
          {
            id: data.drama_supplier_id,
            name: data.drama_supplier.name
          }
        ], 'id')
      } else {
        formState.value.drama_supplier_id = undefined
      }
      // 保证已停止合作的成片供应商正常展示
      if (data.film_supplier_id) {
        supplierListFilm.value = Pub.mergeArr(supplierListFilm.value, [
          {
            id: data.film_supplier_id,
            name: data.film_supplier.name
          }
        ], 'id')
      } else {
        formState.value.film_supplier_id = undefined
      }
      // 版权期限处理
      if (data.copyright_date == 1) {
        formState.value.copyrightTermType = 1
        // 需要先初始化，避免从【有效期】切换为【永久】时报错
        formState.value.copyright_date = undefined
      } else {
        formState.value.copyrightTermType = 2
        formState.value.copyright_date = dayjs(data.copyright_date)
      }
      // 剧情类型
      formState.value.type_ids = data.drama_types.map(item => item.id)
      // 剧情标签
      formState.value.tag_ids = data.tags.map(item => item.id)
      // 价格
      formState.value.money = Pub.KEEP_NUMBER_DECIMAL(data.money / 100, 2)
      // 剧本价格
      formState.value.drama_money = Pub.KEEP_NUMBER_DECIMAL(data.drama_money / 100, 2)
      // 全集解锁价格
      formState.value.drama_price = Pub.KEEP_NUMBER_DECIMAL(data.drama_price / 100, 2)
      // 全集解锁价格
      formState.value.special_price = Pub.KEEP_NUMBER_DECIMAL(data.special_price / 100, 2)
      // 演员
      formState.value.actor_list = data.actor_list || []
      // if (!formState.value.actor_list.length) { touchAddActor() }
    } else {
      message.error(res.message)
    }
  }).catch(() => {
    isLoading.value = false
    message.error('获取详情失败')
  })
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 允许
  return true
}

// 上传结果
function uploadResult (item, fileJson, res, record) {
  // 上传结果判断
  if (fileJson.status === 'done') {
    RefForm.value.validate()
    item.photo_material = [fileJson]
  }
}

// 准备上传（视频）
function beforeUploadVideoPro (file) {
  return new Promise((resolve, reject) => {
    // 视频类型
    if (!file.type.includes('video')) {
      message.error('请上传正确的视频类型文件')
      reject()
      return
    }
    // 视频时长
    var url = URL.createObjectURL(file)
    var audioElement = new Audio(url)
    audioElement.addEventListener("loadedmetadata", () => {
      // 超过指定时长
      if (Math.trunc(audioElement.duration) > 120) {
        message.error('请上传时长2分钟之内的视频文件')
        reject()
      } else {
        // 允许上传
        resolve()
      }
    })
  })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 竖版封面处理
    const covers = res.cover
    const cover = []
    covers.forEach(item => {
      cover.push({
        name: item.name,
        url: item.url,
        key: item.key,
        open_pic_id: item.open_pic_id,
        img_key: item.img_key
      })
    })
    res.cover = cover
    // 横版封面处理
    const horizontalCovers = res.horizontalCover
    const horizontalCover = []
    horizontalCovers.forEach(item => {
      horizontalCover.push({
        name: item.name,
        url: item.url,
        key: item.key,
        open_pic_id: item.open_pic_id,
        img_key: item.img_key
      })
    })
    res.horizontalCover = horizontalCover
    // 视频集锦处理
    // const videos = res.video_highlight
    // const isUploadSuccess = videos.every(item => {
    //   return item.status === undefined || item.status === 'done'
    // })
    // if (!isUploadSuccess) {
    //   message.error('视频集锦正在上传中...')
    //   return
    // }
    // const video = []
    // videos.forEach(item => {
    //   if (item.url) {
    //     video.push({
    //       name: item.name,
    //       url: item.url,
    //       key: item.key
    //     })
    //   }
    // })
    // res.video_highlight = video
    // 外采类型时，有效期单独处理
    if (res.film_type === 2) {
      // 有效期处理
      if (res.copyrightTermType === 1) {
        res.copyright_date = 1
      } else {
        res.copyright_date = res.copyright_date.format(dateFormat)
      }
    }
    // 广点通类目
    let gdt_category = []
    if (res.gdt_category_ids.length) {
      const type2 = gdtTypeSubList.value.find(item => item.value === res.gdt_category_ids[0])
      const type3 = type2.children.find(item => item.value === res.gdt_category_ids[1])
      gdt_category = [
        {
          value: type2.value,
          label: type2.label
        },
        {
          value: type3.value,
          label: type3.label
        }
      ]
    }
    // 上传数据
    const params = {
      id: filmJson.value && filmJson.value.id,
      ...res,
      cover,
      horizontal_cover: horizontalCover,
      money: res.money * 1000 / 10,
      drama_money: res.drama_money * 1000 / 10,
      drama_price: res.drama_price * 1000 / 10,
      special_price: res.special_price * 1000 / 10,
      gdt_category
    }
    if (!!params.id) {
      // 修改
      isLoading.value = true
      dramaEdit(params).then((res) => {
        isLoading.value = false
        if (res.code === 0) {
          message.success('保存成功')
          onClose()
          emit('success')
        } else {
          message.error(res.message)
        }
      }).catch(() => {
        isLoading.value = false
        message.error('保存失败')
      })
    } else {
      // 新增
      isLoading.value = true
      dramaAdd(params).then((res) => {
        isLoading.value = false
        if (res.code === 0) {
          message.success('提交成功')
          onClose()
          emit('success')
        } else {
          message.error(res.message)
        }
      }).catch(() => {
        isLoading.value = false
        message.error('提交失败')
      })
    }
  })).catch((err) => {
    console.log(err)
  })
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState.value[coverKey].splice(index, 1)
}
// 移除视频集锦
function touchDeleteVideo (index) {
  formState.value.video_highlight.splice(index, 1)
}
// 预览视频集锦
function touchPlayVideo (index, item) {
  RefPreviewModal.value.showModal(item.url)
}

// 切换剧情分类
function onMaleAndFemaleChange (e) {
  const typeJson = gdtTypeList.value.find(item => item.value === e) || {}
  const children = typeJson.children || []
  gdtTypeSubList.value = children
}

// 效验 - 封面
function validatorCover (rule, value) {
  if (value && value.length === 0) {
    return Promise.reject('请上传封面')
  }
  return Promise.resolve()
}
// 效验 - 视频集锦
function validatorVideos (rule, value) {
  if (value && value.length === 0) {
    return Promise.reject('请上传视频集锦')
  }
  return Promise.resolve()
}
function validatorWechatDramaId (rule, value) {
  if (value && !Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为纯数字')
  }
  return Promise.resolve()
}
// 效验 - 总集数
function validatortotal_ep (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (value < 1 || value > 10000 ) {
    return Promise.reject('取值范围 1-10000')
  }
  return Promise.resolve()
}
// 效验 - 价格
function validatorMoney (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}
// 效验 - 分成比例
function validatorCashRate (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}
// 效验 - 版权期限类型
function validatorCopyrightTermType (rule, value) {
  // 有效期
  if (!value) { return Promise.reject('请选择版权期限类型') }
  return Promise.resolve()
}
// 效验 - 版权期限
function validatorCopyrightTerm (rule, value) {
  // 有效期
  if (!value) { return Promise.reject('请选择版权期限') }
  return Promise.resolve()
}

// 获取供应商列表
function getSupplierAll (type) {
  supplierAll({
    friendship_status: 1,
    page: 1,
    page_size: 10000,
    type
  }).then(res => {
    const { code, data } = res
    if (code === 0) {
      // 1摄制 2剧本 3成片
      switch(type) {
        case 1:
          supplierListShot.value = data
          break;
        case 2:
          supplierListDrama.value = data
          break;
        case 3:
          supplierListFilm.value = data
          break;
      }
    }
  })
}

// 获取广点通类型
function getDramaGdtTypeList () {
  const params = {
    page: 1,
    page_size: 1000
  }
  dramaGdtTypeList(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      gdtTypeList.value = data
    }
  })
}

// 获取剧情类型列表
function getPlotTypes () {
  const params = {
    source: ClassifySettings.type,
    status: 1,
    page: 1,
    page_size: 10000
  }
  getAllClassifyList(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      plotTypes.value = data.list
    }
  })
}

// 获取剧情标签列表
function getPlotTags () {
  const params = {
    source: ClassifySettings.tag,
    status: 1,
    page: 1,
    page_size: 10000
  }
  getAllClassifyList(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      plotTags.value = data.list
    }
  })
}

// 校验演员信息
function validatorActor (rule, value) {
  if (!value.photo_material.length) {
    return Promise.reject('请上传演员头像')
  } else if (!value.name) {
    return Promise.reject('请填写演员姓名')
  } else if (!value.role) {
    return Promise.reject('请填写演员角色')
  } else if (!value.profile) {
    return Promise.reject('请填写演员简介')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.image-view.upload-image-horizontal {
  width: 208px;
  height: 124px;
}
.sync-tt {
  padding-top: 6px;
}
.recharge-delete {
  margin-left: 12px;
  white-space: nowrap;
}
</style>