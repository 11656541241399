import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ================================= 《 版权中心 》

// ================================= 《 内容管理 》 

// 片库添加
export function dramaAdd(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_add',
    method: 'post',
    data: parameter
  })
}

// 片库修改
export function dramaEdit(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_edit',
    method: 'post',
    data: parameter
  })
}

// 片库详情
export function dramaDetail(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_detail',
    method: 'get',
    params: parameter
  })
}

// 广点通分类列表
export function dramaGdtTypeList(parameter) {
  return axios({
    url: BASE_URL + '/drama/gdt_type_list',
    method: 'get',
    params: parameter
  })
}

// 同步抖音图片
export function ttSyncImage(parameter) {
  return axios({
    url: BASE_URL + '/examine/sync_image',
    method: 'post',
    data: parameter
  })
}

// 同步快手图片
export function ksSyncImage(parameter) {
  return axios({
    url: BASE_URL + '/drama/sync_ks_image',
    method: 'post',
    data: parameter
  })
}

// 片库列表
export function dramaList(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_list',
    method: 'get',
    params: parameter
  })
}

// 剧集列表
export function videoList(parameter) {
  return axios({
    url: BASE_URL + '/drama/video_list',
    method: 'get',
    params: parameter
  })
}

// 片库分发
export function dramaDistribute(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_distribute',
    method: 'post',
    data: parameter
  })
}

// 剧集上传或更换
export function videoUpload(parameter) {
  return axios({
    url: BASE_URL + '/drama/video_upload',
    method: 'post',
    data: parameter
  })
}

// 已存在剧集
export function existVideo(parameter) {
  return axios({
    url: BASE_URL + '/drama/exist_video',
    method: 'get',
    params: parameter
  })
}

// 导出微信上传审核列表
export function exportWechatVideos(parameter) {
  return axios({
    url: BASE_URL + '/drama/export_wechat_videos',
    method: 'get',
    params: parameter
  })
}

// 同步媒资
export function videoMedia(parameter) {
  return axios({
    url: BASE_URL + '/drama/sync_video_media',
    method: 'post',
    data: parameter
  })
}

// 抖音同步视频
export function ttSyncVideo(parameter) {
  return axios({
    url: BASE_URL + '/examine/sync_video',
    method: 'post',
    data: parameter
  })
}

// 快手同步视频
export function ksSyncVideo(parameter) {
  return axios({
    url: BASE_URL + '/drama/sync_ks_videos',
    method: 'post',
    data: parameter
  })
}

// 快手发布视频
export function ksPushVideo(parameter) {
  return axios({
    url: BASE_URL + '/drama/publish_video',
    method: 'post',
    data: parameter
  })
}

// 设置剧集介绍
export function videoDescription(parameter) {
  return axios({
    url: BASE_URL + '/drama/video_description_set',
    method: 'post',
    data: parameter
  })
}

// 不分页项目列表
export function projectList(parameter) {
  return axios({
    url: BASE_URL + '/drama/project_list',
    method: 'get',
    params: parameter
  })
}

// 分发记录
export function distributeList(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_distribute_list',
    method: 'get',
    params: parameter
  })
}

// 分发详情
export function distributeDetail(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_distribute_detail',
    method: 'get',
    params: parameter
  })
}

// 分发记录编辑
export function distributeEdit(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_distribute_edit',
    method: 'post',
    data: parameter
  })
}


// 短剧列表
export function dramaAll(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_all',
    method: 'get',
    params: parameter
  })
}

// 视频导入
export function videoExport(parameter) {
  return axios({
    url: BASE_URL + '/drama/video_export',
    method: 'post',
    data: parameter
  })
}

// 抖小标签
export function dyTags(parameter) {
  return axios({
    url: BASE_URL + '/drama/dy_tag',
    method: 'get',
    params: parameter
  })
}

// 短剧创建
export function dySyncCreate(parameter) {
  return axios({
    url: BASE_URL + '/examine/sync_create',
    method: 'post',
    data: parameter
  })
}

// 短剧编辑/绑定
export function dySyncEdit(parameter) {
  return axios({
    url: BASE_URL + '/examine/sync_edit',
    method: 'post',
    data: parameter
  })
}

// 短剧送审
export function dyExamineDrama(parameter) {
  return axios({
    url: BASE_URL + '/examine/examine_drama',
    method: 'post',
    data: parameter
  })
}

// 短剧上线
export function dyOnlineDrama(parameter) {
  return axios({
    url: BASE_URL + '/examine/online_drama',
    method: 'post',
    data: parameter
  })
}

// 授权列表
export function dyDramaList(parameter) {
  return axios({
    url: BASE_URL + '/examine/drama_project',
    method: 'get',
    params: parameter
  })
}

// 短剧授权
export function dyAuthDrama(parameter) {
  return axios({
    url: BASE_URL + '/examine/auth_drama',
    method: 'post',
    data: parameter
  })
}

// 授权列表
export function wxDramaList(parameter) {
  return axios({
    url: BASE_URL + '/drama/wx_drama_project',
    method: 'get',
    params: parameter
  })
}

// 短剧授权
export function wxAuthDrama(parameter) {
  return axios({
    url: BASE_URL + '/drama/wx_auth_drama',
    method: 'post',
    data: parameter
  })
}

// 微小 - 编辑信息
export function wxEditDrama(parameter) {
  return axios({
    url: BASE_URL + '/drama/wx_edit_drama',
    method: 'post',
    data: parameter
  })
}

// 快小 - 题材类型
export function ksTags(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_tag',
    method: 'get',
    params: parameter
  })
}

// 快小 - 片库 — 同步快小 —创建、编辑
export function ksSave(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_save',
    method: 'post',
    data: parameter
  })
}

// 快小 - 片库 — 同步快小 — 送审
export function ksExamine(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_examine',
    method: 'post',
    data: parameter
  })
}

// 片库状态列表
export function dramaStatusList(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_status_list',
    method: 'get',
    params: parameter
  })
}

// 快小 - 片库 — 同步快小 — 上架
export function ksOnline(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_online',
    method: 'post',
    data: parameter
  })
}

// 快小 - 片库 — 同步快小 — 刷新审核状态
export function ksRefresh(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_detail',
    method: 'post',
    data: parameter
  })
}

// 快小 - 片库 — 同步快小 — 授权项目列表
export function ksDramaProjectList(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_drama_project',
    method: 'get',
    params: parameter
  })
}

// 快小 - 片库 — 同步快小 — 授权/取消
export function ksAuthDrama(parameter) {
  return axios({
    url: BASE_URL + '/drama/ks_auth_drama',
    method: 'post',
    data: parameter
  })
}

// 小红书 - 片库 — 同步剧集
export function xhsSyncDrama(parameter) {
  return axios({
    url: BASE_URL + '/drama/sync_xhs_video_media',
    method: 'post',
    data: parameter
  })
}

// 片库状态修改
export function dramaSetStatus(parameter) {
  return axios({
    url: BASE_URL + '/drama/set_drama_status',
    method: 'post',
    data: parameter
  })
}

// 短剧授权
export function dyBindPage(parameter) {
  return axios({
    url: BASE_URL + '/examine/bind_page',
    method: 'post',
    data: parameter
  })
}

// 默认付费模版
export function chargePanelAll(parameter) {
  return axios({
    url: BASE_URL + '/reseller/charge_panel_all_new',
    method: 'get',
    params: parameter
  })
}

// 默认付费模版
export function chargeAll(parameter) {
  return axios({
    url: BASE_URL + '/reseller/charge_all_new',
    method: 'get',
    params: parameter
  })
}

// 推荐频道 - 列表
export function recommendList(parameter) {
  return axios({
    url: BASE_URL + '/drama/recommend_list',
    method: 'get',
    params: parameter
  })
}

// 推荐频道 - 新增
export function recommendAdd(parameter) {
  return axios({
    url: BASE_URL + '/drama/recommend_add',
    method: 'post',
    data: parameter
  })
}

// 推荐频道 - 频道删除
export function recommendDelete(parameter) {
  return axios({
    url: BASE_URL + '/drama/recommend_delete',
    method: 'post',
    data: parameter
  })
}

// 推荐频道 - 权重修改
export function recommendWeightSave(parameter) {
  return axios({
    url: BASE_URL + '/drama/recommend_weight_save',
    method: 'post',
    data: parameter
  })
}

// 推荐频道 - 剧集列表
export function recommendVideoEqList(parameter) {
  return axios({
    url: BASE_URL + '/drama/recommend_video_list',
    method: 'get',
    params: parameter
  })
}

// 剧集状态变更日志 - 列表
export function dramaLogList(parameter) {
  return axios({
    url: BASE_URL + '/drama/drama_log_list',
    method: 'get',
    params: parameter
  })
}


// ================================= 《 供应商管理 》 

// 供应商添加或修改
export function supplierSave(parameter) {
  return axios({
    url: BASE_URL + '/supplier/supplier_save',
    method: 'post',
    data: parameter
  })
}

// 供应商下拉列表
export function supplierAll(parameter) {
  return axios({
    url: BASE_URL + '/supplier/supplier_all',
    method: 'get',
    params: parameter
  })
}

// 供应商列表
export function supplierList(parameter) {
  return axios({
    url: BASE_URL + '/supplier/supplier_list',
    method: 'get',
    params: parameter
  })
}

// 供应商详情
export function supplierDetail(parameter) {
  return axios({
    url: BASE_URL + '/supplier/supplier_detail',
    method: 'get',
    params: parameter
  })
}

// ================================= 《 分销管理（旧） 》

// 二级分销列表
export function distributorAgentList(parameter) {
  return axios({
    url: BASE_URL + '/distributor/agent_list',
    method: 'get',
    params: parameter
  })
}

// 分销商项目列表
export function distributorProjectList(parameter) {
  return axios({
    url: BASE_URL + '/distributor/project_list',
    method: 'get',
    params: parameter
  })
}

// 分销商列表
export function distributorList(parameter) {
  return axios({
    url: BASE_URL + '/distributor/list',
    method: 'get',
    params: parameter
  })
}

// 添加或修改分销商
export function distributorSave(parameter) {
  return axios({
    url: BASE_URL + '/distributor/save',
    method: 'post',
    data: parameter
  })
}

// 分销商详情
export function distributorDetail(parameter) {
  return axios({
    url: BASE_URL + '/distributor/detail',
    method: 'get',
    params: parameter
  })
}

// 每日分销数据
export function distributorTotalDayData(parameter) {
  return axios({
    url: BASE_URL + '/distributor/total_day_data',
    method: 'get',
    params: parameter
  })
}

// 分销商数据
export function distributorData(parameter) {
  return axios({
    url: BASE_URL + '/distributor/distributor_data',
    method: 'get',
    params: parameter
  })
}

// 分销商数据 - 每日数据
export function distributorDayData(parameter) {
  return axios({
    url: BASE_URL + '/distributor/distributor_day_data',
    method: 'get',
    params: parameter
  })
}

// 分销商数据 - 短剧数据
export function distributorDramaData(parameter) {
  return axios({
    url: BASE_URL + '/distributor/drama_data',
    method: 'get',
    params: parameter
  })
}

// 分销结算单
export function distributorCashList(parameter) {
  return axios({
    url: BASE_URL + '/distributor/cash_list',
    method: 'get',
    params: parameter
  })
}

// 完成打款
export function distributorSetCash(parameter) {
  return axios({
    url: BASE_URL + '/distributor/set_cash',
    method: 'post',
    data: parameter
  })
}

// 结算单详情列表
export function distributorCashDetailList(parameter) {
  return axios({
    url: BASE_URL + '/distributor/cash_detail_list',
    method: 'get',
    params: parameter
  })
}

// ===================================《 用户退款 》

// 发起退款
export function refundOrder(parameter) {
  return axios({
    url: BASE_URL + `/order/refund_order`,
    method: 'post',
    data: parameter
  })
}

// 退款
export function refund(parameter) {
  return axios({
    url: BASE_URL + `/order/refund`,
    method: 'post',
    data: parameter
  })
}

// 退款单列表
export function refundList(parameter) {
  return axios({
    url: BASE_URL + `/order/refund_list`,
    method: 'get',
    params: parameter
  })
}

// ================================= 《 分销管理 》 

// 分销商列表
export function resellerList(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_list`,
    method: 'get',
    params: parameter
  })
}

// 分销商详情
export function resellerDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_detail`,
    method: 'get',
    params: parameter
  })
}

// 分销商添加或修改
export function resellerSave(parameter) {
  return axios({
    url: BASE_URL + `/reseller/save`,
    method: 'post',
    data: parameter
  })
}

// 平台列表
export function resellerPlatformList(parameter) {
  return axios({
    url: BASE_URL + `/reseller/platform_list`,
    method: 'get',
    params: parameter
  })
}

// 分销商片库列表
export function resellerDramaList(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_drama_list`,
    method: 'get',
    params: parameter
  })
}

// 片库设置-下载（单个）
export function resellerIsDownload(parameter) {
  return axios({
    url: BASE_URL + `/reseller/is_download`,
    method: 'post',
    data: parameter
  })
}

// 片库设置-可见（单个）
export function resellerIsVisible(parameter) {
  return axios({
    url: BASE_URL + `/reseller/is_visible`,
    method: 'post',
    data: parameter
  })
}

// 片库设置-下载（批量）
export function resellerIsAllDownload(parameter) {
  return axios({
    url: BASE_URL + `/reseller/is_all_download`,
    method: 'post',
    data: parameter
  })
}

// 片库设置-可见（批量）
export function resellerIsAllVisible(parameter) {
  return axios({
    url: BASE_URL + `/reseller/is_all_visible`,
    method: 'post',
    data: parameter
  })
}

// 商务列表（搜索）- 列表
export function resellerBusiness(parameter) {
  return axios({
    url: BASE_URL + `/reseller/business_list`,
    method: 'get',
    params: parameter
  })
}

// 分销商（搜索）- 列表
export function resellerUser(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_user`,
    method: 'get',
    params: parameter
  })
}

// 短剧列表（搜索）- 列表
export function resellerDrama(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_drama`,
    method: 'get',
    params: parameter
  })
}

// 项目列表（搜索）- 列表
export function resellerProject(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_project`,
    method: 'get',
    params: parameter
  })
}

// 分销商数据 - 列表
export function resellerData(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_data`,
    method: 'get',
    params: parameter
  })
}

// 短剧分销数据 - 列表
export function resellerDramaData(parameter) {
  return axios({
    url: BASE_URL + `/reseller/reseller_drama_data`,
    method: 'get',
    params: parameter
  })
}

// 分销结算单 - 列表
export function resellerCashList(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_cash_list`,
    method: 'get',
    params: parameter
  })
}

// 分销结算单 - 详情
export function resellerCashDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_cash_detail`,
    method: 'get',
    params: parameter
  })
}

// 分销结算单 - 打款
export function resellerCashPay(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_cash_pay`,
    method: 'post',
    data: parameter
  })
}

// iap分销结算单 - 明细数据
export function resellerCashDayDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_cash_day_detail`,
    method: 'get',
    params: parameter
  })
}

// iaa分销结算单 - 明细数据
export function iaaResellerCashDayDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_iaa_cash_day_detail`,
    method: 'get',
    params: parameter
  })
}

// cpm分销结算单 - 明细数据
export function cpmResellerCashDayDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_cpm_cash_day_detail`,
    method: 'get',
    params: parameter
  })
}

// ================================= 《 运营管理 》 

// 快手结算 - 结算列表
export function settlementList(parameter) {
  return axios({
    url: BASE_URL + `/settlement/settlement_list`,
    method: 'get',
    params: parameter
  })
}

// 快手结算 - 结算详情
export function settlementDetail(parameter) {
  return axios({
    url: BASE_URL + `/settlement/settlement_detail`,
    method: 'get',
    params: parameter
  })
}

// 快手结算 - 结算
export function settlement(parameter) {
  return axios({
    url: BASE_URL + `/settlement/settlement`,
    method: 'post',
    data: parameter
  })
}

// 项目列表
export function settlementProjectList(parameter) {
  return axios({
    url: BASE_URL + '/user/project_list',
    method: 'get',
    params: parameter
  })
}

// 抖小达人中介 - 列表
export function dyTaskData(parameter) {
  return axios({
    url: BASE_URL + '/dytask/dy_task_data',
    method: 'get',
    params: parameter
  })
}

// 抖小达人中介 - 中介列表
export function dyAgentName(parameter) {
  return axios({
    url: BASE_URL + '/dytask/agent_name',
    method: 'get',
    params: parameter
  })
}

// 企微列表 - 列表
export function wechatAccountList(parameter) {
  return axios({
    url: BASE_URL + '/wechat/wechat_account_list',
    method: 'get',
    params: parameter
  })
}

// 企微列表 - 新建/编辑
export function wechatAccountSave(parameter) {
  return axios({
    url: BASE_URL + '/wechat/wechat_account_save',
    method: 'post',
    data: parameter
  })
}

// 企微列表 - 删除
export function wechatAccountDelete(parameter) {
  return axios({
    url: BASE_URL + '/wechat/wechat_account_delete',
    method: 'post',
    data: parameter
  })
}

// 微小播放器配置 - 列表
export function wechatConfigList(parameter) {
  return axios({
    url: BASE_URL + '/wechat/config_wechat_list',
    method: 'get',
    params: parameter
  })
}

// 微小播放器配置 - 分享配置
export function wechatShare(parameter) {
  return axios({
    url: BASE_URL + '/wechat/wechat_share',
    method: 'post',
    data: parameter
  })
}

// 微小播放器配置 - 推荐位配置
export function wechatRecPosition(parameter) {
  return axios({
    url: BASE_URL + '/wechat/rec_position',
    method: 'post',
    data: parameter
  })
}

// 微小播放器配置 - 公众号绑定
export function wechatBindAccount(parameter) {
  return axios({
    url: BASE_URL + '/wechat/blind_account',
    method: 'post',
    data: parameter
  })
}

// 微小播放器配置 - 公众号解绑
export function wechatUnbindAccount(parameter) {
  return axios({
    url: BASE_URL + '/wechat/unblind_account',
    method: 'post',
    data: parameter
  })
}

// 播放记录 - 列表
export function videoPlayRecordList(parameter) {
  return axios({
    url: BASE_URL + '/play/video_play_list',
    method: 'get',
    params: parameter
  })
}

// 活动管理 - 列表
export function activityList(parameter) {
  return axios({
    url: BASE_URL + '/activity/list',
    method: 'get',
    params: parameter
  })
}

// 活动管理 - 小程序列表
export function activityProjectAll(parameter) {
  return axios({
    url: BASE_URL + '/activity/project_all',
    method: 'get',
    params: parameter
  })
}

// 活动管理 - 保存
export function activitySave(parameter) {
  return axios({
    url: BASE_URL + '/activity/save',
    method: 'post',
    data: parameter
  })
}

// 视频号挂载数据 - 列表
export function videoFinderList(parameter) {
  return axios({
    url: BASE_URL + '/finder/list',
    method: 'get',
    params: parameter
  })
}

// 视频号挂载数据 - 刷新数据
export function videoFinderRefreshVideoData(parameter) {
  return axios({
    url: BASE_URL + '/finder/refresh_video_data',
    method: 'get',
    params: parameter
  })
}

// 视频号挂载数据 - 获取刷新时间
export function videoFinderRefreshTime(parameter) {
  return axios({
    url: BASE_URL + '/finder/refresh_time',
    method: 'get',
    params: parameter
  })
}

//内容管理 - 内容聚合页列表
export function aggregationPageList(parameter) {
  return axios({
    url: BASE_URL + '/aggregation/list',
    method: 'get',
    params: parameter
  })
}

//内容管理 - 内容聚合页保存
export function aggregationPageSave(parameter) {
  return axios({
    url: BASE_URL + '/aggregation/save',
    method: 'post',
    data: parameter
  })
}

//内容管理 - 榜单页列表
export function rankingList(parameter) {
  return axios({
    url: BASE_URL + '/ranking/list',
    method: 'get',
    params: parameter
  })
}

//内容管理 - 榜单页保存
export function rankingSave(parameter) {
  return axios({
    url: BASE_URL + '/ranking/save',
    method: 'post',
    data: parameter
  })
}

// 运营管理 - 充值页列表
export function chargePageList(parameter) {
  return axios({
    url: BASE_URL + '/charge_page/list',
    method: 'get',
    params: parameter
  })
}

// 运营管理 - 充值页保存
export function chargePageSave(parameter) {
  return axios({
    url: BASE_URL + '/charge_page/save',
    method: 'post',
    data: parameter
  })
}


// 运营管理 - 用户投诉列表
export function ComplaintsList(parameter) {
  return axios({
    url: BASE_URL + '/complaints/list',
    method: 'get',
    params: parameter
  })
}

// 运营管理 - 用户投诉状态修改
export function ComplaintsSetStatus(parameter) {
  return axios({
    url: BASE_URL + '/complaints/set_status',
    method: 'post',
    data: parameter
  })
}

// 运营管理 - 星图融合任务
export function dyxtTaskData(parameter) {
  return axios({
    url: BASE_URL + '/dytask/dy_xt_task_data',
    method: 'get',
    params: parameter
  })
}

// 运营管理 - 兑换礼品管理 - 列表
export function giftList(parameter) {
  return axios({
    url: BASE_URL + '/gift/list',
    method: 'get',
    params: parameter
  })
}

// 运营管理 - 兑换礼品管理 - 创建/编辑
export function giftSave(parameter) {
  return axios({
    url: BASE_URL + '/gift/save',
    method: 'post',
    data: parameter
  })
}

// 运营管理 - 兑换礼品管理 - 上下移动
export function giftSort(parameter) {
  return axios({
    url: BASE_URL + '/gift/sort',
    method: 'post',
    data: parameter
  })
}

// 运营管理 - 兑换礼品管理 - 上下架
export function giftStatus(parameter) {
  return axios({
    url: BASE_URL + '/gift/gift_status',
    method: 'post',
    data: parameter
  })
}

// 运营管理 - 礼品兑换 - 列表
export function giftOrderList(parameter) {
  return axios({
    url: BASE_URL + '/gift/order_list',
    method: 'get',
    params: parameter
  })
}

// 运营管理 - 礼品兑换 - 列表
export function giftSaveMsg(parameter) {
  return axios({
    url: BASE_URL + '/gift/save_msg',
    method: 'post',
    data: parameter
  })
}

// ================================= 《 达人运营 》 

// 达人团长结算 - 达人团长列表
export function darenList(parameter) {
  return axios({
    url: BASE_URL + '/dytask/daren_list',
    method: 'get',
    params: parameter
  })
}

// 达人团长结算 - 列表
export function darenSettlementList(parameter) {
  return axios({
    url: BASE_URL + '/dytask/daren_settlement_list',
    method: 'get',
    params: parameter
  })
}

// 达人团长结算 - 生成结算
export function darenSettlement(parameter) {
  return axios({
    url: BASE_URL + '/dytask/daren_settlement',
    method: 'post',
    data: parameter
  })
}

// ================================= 《 抖小播放器配置 》 

// 抖音播放器配置列表
export function ttConfigList(parameter) {
  return axios({
    url: BASE_URL + '/douyin/config_douyin_list',
    method: 'get',
    params: parameter
  })
}

// 抖音播放器-开关配置
export function ttRecPosition(parameter) {
  return axios({
    url: BASE_URL + '/douyin/douyin_rec_position',
    method: 'post',
    data: parameter
  })
}

// ================================= 《 视频号达人库 》 

// 视频号列表——检索
export function finderDarenAll(parameter) {
  return axios({
    url: BASE_URL + '/finder/daren_all',
    method: 'get',
    params: parameter
  })
}

// 服务商列表——检索 type: 0-全部，1-已绑定视频号服务商
export function finderProviderAll(parameter) {
  return axios({
    url: BASE_URL + '/finder/provider_all',
    method: 'get',
    params: parameter
  })
}

// 视频号达人库——列表
export function finderDarenList(parameter) {
  return axios({
    url: BASE_URL + '/finder/daren_list',
    method: 'get',
    params: parameter
  })
}

// 视频号达人库——编辑/创建
export function finderDarenSave(parameter) {
  return axios({
    url: BASE_URL + '/finder/daren_save',
    method: 'post',
    data: parameter
  })
}

// 视频号达人库——服务商列表
export function finderProviderList(parameter) {
  return axios({
    url: BASE_URL + '/finder/provider_list',
    method: 'get',
    params: parameter
  })
}

// 视频号达人库——服务商编辑/创建
export function finderProviderSave(parameter) {
  return axios({
    url: BASE_URL + '/finder/provider_save',
    method: 'post',
    data: parameter
  })
}